body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Embed-container {
  height: 100vh;
}

.buyin-icon * {
  fill: black;
}

.icon-selected .buyin-icon * {
  fill: #e41b13;
}
.icon-selected-medium .buyin-icon * {
  fill: #e41b13;
  width: 30px;
}

.buyin-icon-stroke *{
  fill: black;
  stroke: black;

}

.icon-selected .buyin-icon-stroke * {
  fill: #e41b13;
  stroke: #e41b13;
}

.tools-icon * {
  fill: black;
}

.tools-icon {
  width: 30px;
  height: 30px;
}